import cmsClient from '@/lib/cms-client';

import type { EventRegistrationFields, EventRegistrationPayload } from '@/types/event';

export async function getEventPage({ slug, preview = false }: { slug: string; preview?: boolean }) {
  return await cmsClient(`/event/findByKey/${slug}?preview=${preview}`);
}

export async function getEventPages() {
  return await cmsClient(`/events`);
}

export function mapRegistrationPayload({
  utm_source,
  utm_medium,
  utm_campaign,
  utm_content,
  ...payload
}: EventRegistrationFields): EventRegistrationPayload {
  return {
    ...payload,
    UTM_Source_Last__c: utm_source || '',
    UTM_Medium_Last__c: utm_medium || '',
    UTM_Campaign_Last__c: utm_campaign || '',
    UTM_Content_Last__c: utm_content || '',
  };
}
