import styled from 'styled-components';

import { Title } from '@/components/core/Title';
import { Container } from '@/components/core';
import theme from '@/utils/styles-variables';

export const Wrapper = styled.div`
  min-height: 800px;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  & > section {
    padding: 0;
  }

  .isAiSummit {
    width: 100%;
  }

  @media (max-width: ${theme.breakpoints.max_md}px) {
    min-height: 640px;
  }
`;

export const ContainerElement = styled(Container)`
  position: relative;
`;

export const Header = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;

  &.isAiSummit {
    flex-direction: column;
    align-items: center;
    margin-top: 48px;
  }
`;

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  text-align: center;
  position: relative;
  max-width: 70%;
  @media (max-width: ${theme.breakpoints.max_md}px) {
    max-width: 696px;
  }
`;

export const HeaderTitle = styled(Title).attrs({ forwardedAs: 'h1', type: 'secondary' })`
  position: relative;
  z-index: 2;
  font-weight: 400;
  padding-bottom: 48px;

  b,
  strong {
    color: ${theme.colors.white};
    font-weight: 700;
  }

  &.isAiSummit {
    font-family: var(--ui-font-family-gilmer);
    font-size: 30px;
    font-weight: 400;
    letter-spacing: 2px;
    text-transform: uppercase;
  }

  @media (min-width: ${theme.breakpoints.min_md}px) and (max-width: ${theme.breakpoints.max_md}px) {
    font-size: 45px;
    line-height: 56px;
  }

  @media (max-width: ${theme.breakpoints.max_md}px) {
    &.isAiSummit {
      font-size: 25px;
    }
  }

  @media (min-height: 1200px) {
    &.isAiSummit {
      padding-bottom: 100px;
    }
  }
`;

export const HeaderEventDate = styled.p`
  font-family: ${theme.fonts.title};
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
  color: ${theme.colors.white};
  width: 100%;
  text-align: center;
  position: relative;
  z-index: 2;

  &.isAiSummit {
    font-weight: 400;
    text-transform: uppercase;
    white-space: pre-wrap;

    em {
      font-weight: 700;
      font-style: italic;
    }
  }

  @media (min-width: ${theme.breakpoints.min_md}px) {
    max-width: 100%;
    font-size: 25px;
    line-height: 32px;
  }

  @media (min-width: ${theme.breakpoints.min_lg}px) {
    font-size: 30px;
    line-height: 40px;
  }
`;

export const DisplayWrapper = styled.div`
  display: flex;
  justify-content: center;
  color: #000;
  width: 100%;
  z-index: 2;
  position: relative;
  margin-top: 48px;

  @media (max-width: ${theme.breakpoints.max_sm}px) {
    margin-top: 32px;
  }
`;

export const ButtonWrapper = styled.div`
  text-align: center;
  margin-top: 48px;
  position: relative;
  z-index: 2;

  &.isAiSummit {
    text-transform: uppercase;
  }

  @media (max-width: ${theme.breakpoints.max_sm}px) {
    margin-top: 32px;
  }
`;
export const Video = styled.video`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
`;
export const VideoOverlay = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  background-color: ${(props) => props.theme.colors.grey.dark};
  opacity: 0.6;
`;

export const Logo = styled.figure`
  position: relative;
  width: 100%;
  z-index: 1;
  margin-bottom: 16px;
  height: auto;
  max-height: 116px;
  padding-bottom: 8%;

  img {
    object-fit: contain;
    filter: brightness(0) invert(1);
  }

  &.space {
    margin-bottom: 85px;
  }

  @media (max-width: ${theme.breakpoints.max_md}px) {
    padding-bottom: 9%;
    margin-bottom: 32px;

    &.space {
      margin-bottom: 50px;
    }
  }
  @media (max-width: ${theme.breakpoints.max_sm}px) {
    padding-bottom: 11%;
  }

  @media (min-height: 1200px) {
    padding-bottom: 9%;
    margin-bottom: 44px;

    &.space {
      margin-bottom: 171px;
    }
  }
`;
