import styled from 'styled-components';
import { Button } from '@/components/core/Button';

const TertiaryButton = styled(Button)`
  border: 1px solid var(--tertiary-button-border, ${(props) => props.theme.colors.grey.dark});
  color: var(--tertiary-button-color, ${(props) => props.theme.colors.grey.dark});
  background-color: var(--tertiary-button-bg, ${(props) => props.theme.colors.white});

  &:hover {
    border-color: var(--tertiary-button-hover-border);
    background-color: var(--tertiary-button-hover-bg, ${(props) => props.theme.colors.grey.dark});
    color: var(--tertiary-button-hover-color, ${(props) => props.theme.colors.white});
  }
`;

export default TertiaryButton;
