'use client';
import { useLayoutEffect, useState } from 'react';
import useBetterMediaQuery from '@/utils/useBetterMediaQuery';
import theme from '@/utils/styles-variables';
import { StyledScrollDesktop, StyledScrollTablet } from './styles';

export default function ScrollIndicator() {
  const [scrolled, setScrolled] = useState(false);
  const isTablet = useBetterMediaQuery(`(max-width: ${theme.breakpoints.max_sm}px)`);

  useLayoutEffect(() => {
    const onScroll = () => {
      if (window.scrollY >= 82) {
        setScrolled(true);
      }
    };

    window.addEventListener('scroll', onScroll);

    // @ts-ignore
    () => window.removeEventListener('scroll', onScroll);
  }, []);

  return !isTablet ? (
    <StyledScrollDesktop aria-hidden="true" $scrolled={scrolled} />
  ) : (
    <StyledScrollTablet aria-hidden="true" $scrolled={scrolled} />
  );
}
