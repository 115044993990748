export interface SponsorsType {
  darkTheme?: boolean;
  withTagNames?: boolean;
  title: string;
  list: {
    id: number;
    listTitle: string;
    size: SizeEnumType;
    sponsor: {
      id: number;
      img: { url: string; alternativeText: string };
      href: string;
    }[];
  }[];
}

const SizeEnum = {
  Large: 'Large',
  Medium: 'Medium',
  Small: 'Small',
} as const;

export type ValueOf<T> = T[keyof T];
export type SizeEnumType = ValueOf<typeof SizeEnum>;

export function getSize(size: SizeEnumType) {
  switch (size) {
    case SizeEnum.Large:
      return 240;
    case SizeEnum.Medium:
      return 180;
    default:
      return 120;
  }
}
