import { useContext, useMemo } from 'react';

import useBetterMediaQuery from '@/utils/useBetterMediaQuery';
import styled, { css } from 'styled-components';
import { PrimaryButton } from '@/components/core/Button';
import { WhiteButton } from '@/components/core/Button';

import NavigationContext from '@/contexts/navigation';
import ListItem from './Item';

import { Info } from '@/components/homepage/Countdown/types';

import theme from '@/utils/styles-variables';
import EventContext from '@/contexts/event';

type MenuListType = {
  canRegister?: boolean;
  darkTheme?: boolean;
  showAgenda?: boolean;
  info: Info;
  white: boolean;
  prefersReducedMotion?: boolean;
  isExternalRegistration?: boolean;
};

const MenuList = ({
  darkTheme,
  white,
  showAgenda,
  canRegister,
  isExternalRegistration,
}: MenuListType) => {
  const { isOpen } = useContext(NavigationContext);
  const { agenda, event, moreInfo, venue, status, info } = useContext(EventContext);
  const isDesktop = useBetterMediaQuery(`(min-width: ${theme.breakpoints.min_lg}px)`);
  const Button = useMemo(
    () => (!darkTheme && (white || !isDesktop) ? PrimaryButton : WhiteButton),
    [darkTheme, white, isDesktop],
  );

  return (
    <NavListWrapper $isOpen={isOpen}>
      <NavListBox>
        <NavList>
          {event && <ListItem label="Event" url="#event" />}
          {venue && <ListItem label="Venue" url="#venue" />}
          {agenda && showAgenda && <ListItem label="Agenda" url="#agenda"></ListItem>}
          {moreInfo && <ListItem label={moreInfo?.menuLabel || 'More Info'} url="#more-info" />}
          {canRegister && status == 'before' && (
            <ListItem
              label="Register now"
              component={Button}
              extraClass="register-now"
              url={isExternalRegistration ? info?.registrationUrl : '#register-now'}
            />
          )}
        </NavList>
      </NavListBox>
    </NavListWrapper>
  );
};

type NavListWrapperType = {
  $isOpen: Boolean;
};

const NavListWrapper = styled.div<NavListWrapperType>`
  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: var(--nav-bg, white);
    opacity: 0;
    overflow: hidden;
    transition: all 0.4s ease;
    pointer-events: none;

    ${(props) =>
      props.$isOpen &&
      css`
        pointer-events: all;
        opacity: 1;
        z-index: 100;
      `}
  }
`;

const NavListBox = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  text-align: center;
  border-top: 1px solid rgba(31, 34, 44, 0.1);

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    justify-content: flex-start;
    padding-top: 60px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    padding-bottom: 145px;
    position: relative;

    &:before {
      position: fixed;
      content: '';
      bottom: 125px;
      left: 0;
      right: 0;
      height: 20px;
      background-color: var(--nav-bg, white);
      z-index: 10;
    }

    &:after {
      position: fixed;
      content: '';
      bottom: 0;
      left: 0;
      right: 0;
      height: 125px;
      background-color: var(--nav-bg, white);
      z-index: 10;
    }
  }
`;

const NavList = styled.div`
  display: inline-flex;
  list-style: none;

  @media (min-width: ${(props) => props.theme.breakpoints.min_lg}px) {
    justify-content: start;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    display: block;
    flex-direction: column;
    height: 100%;
    overflow-y: auto;
    padding-bottom: 140px;
  }
`;

export default MenuList;
