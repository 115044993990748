import styled, { css } from 'styled-components';
import Image from 'next/image';
import { Section } from '@/components/core';

type RowType = {
  $positioning: number;
};

type ImageType = {
  $small?: boolean;
};

export const CustomSection = styled(Section)`
  background-color: ${({ theme }) => theme.colors.white};
  overflow-x: clip;

  &.darkTheme {
    background-color: transparent;
  }

  &.isAiSummit {
    background-color: ${({ theme }) => theme.eventColors.bg};
  }

  #speakers + & {
    padding-top: 0;
  }
`;

export const ListWrap = styled.div`
  position: relative;

  &.isAiSummit {
    padding-bottom: 136px;

    @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
      --mask-size: 64px;
      --mask-size-bottom: 80px;
      background: linear-gradient(
        to bottom,
        transparent,
        #f46262 calc(var(--mask-size) * 3),
        #3e83ba calc(100% - var(--mask-size-bottom) * 1.5),
        transparent
      );
      padding: var(--mask-size) 24px var(--mask-size-bottom);
      margin-left: -24px;
      margin-top: -40px;
      width: 100vw;
    }

    .bg {
      filter: blur(20px);
      height: 117% !important;
      left: -43% !important;
      top: auto !important;
      width: 3300px !important;
      z-index: -1;

      @media (max-width: ${(props) => props.theme.breakpoints.max_lg}px) {
        height: 125% !important;
        bottom: -64px !important;
        left: -70% !important;
      }

      @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
        display: none;
      }
    }
  }
`;

export const Row = styled.div<RowType>`
  align-items: center;
  display: flex;
  gap: 80px;
  padding: ${({ $positioning }) => ($positioning % 2 === 0 ? '0px' : '80px 0')};
  position: relative;
  flex-direction: ${({ $positioning }) => ($positioning % 2 === 0 ? 'row' : 'row-reverse')};

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    gap: 40px;
    padding: ${({ $positioning }) => ($positioning % 2 === 0 ? '0' : '64px 0')};
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    align-items: stretch;
    padding: ${({ $positioning }) => ($positioning % 2 === 0 ? '0' : '40px 0')};
    flex-direction: column;
    gap: 24px;
  }

  &.isAiSummit {
    position: relative;
  }

  &:not(.isAiSummit):last-child {
    padding-bottom: 0;
  }
`;

export const CustomImage = styled(Image)<ImageType>`
  aspect-ratio: 640 / 480;
  object-fit: cover;
  object-position: center;
  z-index: 1;
  width: 66%;
  height: auto;
  position: relative;
  border-radius: 8px;
  max-height: 668px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_lg}px) {
    width: 50%;
    max-height: 474px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    width: 100%;
    border-radius: 8px;
  }

  ${(props) =>
    props.$small &&
    css`
      @media (min-width: ${(props) => props.theme.breakpoints.min_xl}px) {
        width: 55%;
      }
    `}
`;
