import { useRef, useState } from 'react';
import { Field, Form, Formik } from 'formik';

import {
  FieldsWrapper,
  FormWrapper,
  SuccessDescription,
  SuccessTitle,
  SuccessWrapper,
  OuterWrapper,
  FormTitle,
} from '@/components/RegistrationForm/styles';

import { PrimaryButton } from '@/components/core/Button';

import Textbox from '@/components/core/forms/input/Textbox';
import Select from '@/components/core/forms/input/Select';
import RadioButton from '@/components/core/forms/input/RadioButton';
import InputWrapper from '@/components/core/forms/input/InputWrapper';
import ErrorLabel from '@/components/core/forms/input/ErrorLabel';

import SuccessIcon from './success.svg';

import { registrationVision } from '@/services/marketo.service';
import { useReducedMotion } from '@/utils/useReducedMotion';
import { useCurrentUtm } from '@/utils/useCurrentUtm';
import { mapRegistrationPayload } from '@/services/event.service';

const MAP = {
  yes: true,
  no: false,
};

const INITIAL_FORM_STATUS = { success: false, error: false };

type FormStatus = {
  success: boolean;
  error: boolean;
};

type MapKey = keyof typeof MAP;

type RegistrationFormType = {
  buttonLabel: string;
  title: string;
};

export default function RegistrationForm({ buttonLabel, title }: RegistrationFormType) {
  const utmParams = useCurrentUtm();
  const formWrapEl = useRef<HTMLDivElement>(null);
  const prefersReducedMotion = useReducedMotion();
  const [{ success, error }, setStatus] = useState<FormStatus>(INITIAL_FORM_STATUS);

  return (
    <OuterWrapper>
      <FormWrapper ref={formWrapEl}>
        {success ? (
          <SuccessWrapper>
            <SuccessIcon />
            <SuccessTitle>Registration Successful</SuccessTitle>
            <SuccessDescription>
              Thanks for registering! Your submission is in review and we’ll confirm your
              registration shortly.
            </SuccessDescription>
          </SuccessWrapper>
        ) : (
          <Formik
            isInitialValid={false}
            initialValues={{
              firstName: '',
              lastName: '',
              companyName: '',
              jobTitle: '',
              businessEmail: '',
              permissionToShareCompanyLogo: '',
              foodAllergies: '',
              conferenceNotes: '',
              publicAcknowlegement: '',
              attendeeType: '',
            }}
            onSubmit={async (values) => {
              try {
                setStatus(INITIAL_FORM_STATUS);

                await registrationVision(
                  mapRegistrationPayload({
                    firstName: values.firstName,
                    lastName: values.lastName,
                    company: values.companyName,
                    email: values.businessEmail,
                    title: values.jobTitle,
                    foodAllergies: values.foodAllergies,
                    shareLogo: MAP[values.permissionToShareCompanyLogo as MapKey] || false,
                    publicAcknowlegement: MAP[values.publicAcknowlegement as MapKey] || false,
                    tempTextToken1: values.conferenceNotes,
                    attendeeType: values.attendeeType,
                    ...utmParams,
                  }),
                );

                setStatus({ success: true, error: false });

                formWrapEl.current?.scrollIntoView({
                  behavior: prefersReducedMotion ? 'auto' : 'smooth',
                });
              } catch (e) {
                setStatus({ success: false, error: true });
                //Sentry.captureException(e);
              }
            }}
          >
            {({ values, errors, touched, isSubmitting, setFieldValue }) => (
              <Form noValidate>
                <FormTitle as="h4">{title}</FormTitle>

                <FieldsWrapper>
                  <Field
                    component={Textbox}
                    type="text"
                    name="firstName"
                    label="First Name*"
                    ariaLabel="First Name"
                    placeholder="Your first name"
                    validate={(value: string) => {
                      const nameRegex = /^[A-Za-z-\s\u00c0-\u017e]+$/;
                      return !nameRegex.test(value.trim());
                    }}
                    error={errors.firstName && touched.firstName}
                    errorLabel={
                      values.firstName ? 'First Name is invalid' : 'This field is required'
                    }
                  />
                  <Field
                    component={Textbox}
                    type="text"
                    name="lastName"
                    label="Last Name*"
                    ariaLabel="last Name"
                    placeholder="Your last name"
                    validate={(value: string) => {
                      const nameRegex = /^[A-Za-z-\s\u00c0-\u017e]+$/;
                      return !nameRegex.test(value.trim());
                    }}
                    error={errors.lastName && touched.lastName}
                    errorLabel={values.lastName ? 'Last Name is invalid' : 'This field is required'}
                  />
                  <Field
                    component={Textbox}
                    className="fullWidth"
                    type="text"
                    name="businessEmail"
                    label="Business Email*"
                    ariaLabel="Business Email"
                    placeholder="Your business email address"
                    validate={(value: string) => {
                      const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
                      return !emailRegex.test(value);
                    }}
                    error={errors.businessEmail && touched.businessEmail}
                    errorLabel={
                      values.businessEmail.trim()
                        ? 'Business Email Address is invalid'
                        : 'This field is required'
                    }
                  />
                  <Field
                    component={Textbox}
                    className="fullWidth"
                    type="text"
                    name="companyName"
                    label="Company*"
                    ariaLabel="Company Name"
                    placeholder="Your company name"
                    validate={(value: string) => {
                      const alphabeticRegex = /^[A-Za-z\d\s\u00c0-\u017e]+$/;
                      return !alphabeticRegex.test(value.trim());
                    }}
                    error={errors.companyName && touched.companyName}
                    errorLabel={
                      values.companyName.trim()
                        ? 'Company name is invalid'
                        : 'This field is required'
                    }
                  />
                  <Field
                    component={Textbox}
                    className="fullWidth"
                    type="text"
                    name="jobTitle"
                    label="Job Title*"
                    ariaLabel="Job Title"
                    placeholder="Your job title"
                    validate={(value: string) => {
                      const nameRegex = /^[A-Za-z-\s\u00c0-\u017e]+$/;
                      return !nameRegex.test(value.trim());
                    }}
                    error={errors.jobTitle && touched.jobTitle}
                    errorLabel={
                      values.jobTitle.trim() ? 'Job Title is invalid' : 'This field is required'
                    }
                  />
                  <Field
                    component={Select}
                    className="fullWidth"
                    name="attendeeType"
                    label="Attendee Type*"
                    placeholder="Select an option"
                    validate={(value: string) => {
                      return value.trim().length < 2;
                    }}
                    error={errors.attendeeType && touched.attendeeType}
                    errorLabel="This field is required"
                    required
                    options={['Client', 'Speaker', 'Partner', 'Other']?.map((value) => ({
                      label: value,
                      value,
                    }))}
                  />
                  <Field
                    component={Textbox}
                    className="fullWidth"
                    type="text"
                    name="foodAllergies"
                    label="Food allergies"
                    ariaLabel="Food allergies"
                    placeholder="Describe any allergies you may have"
                  />
                  <Field
                    component={Textbox}
                    className="fullWidth"
                    type="text"
                    name="conferenceNotes"
                    label="What’s the number one topic you’d like to hear about at Sword Vision 2024?"
                  />

                  <InputWrapper
                    label="Can we publish your company’s attendance on this site?*"
                    error={errors.publicAcknowlegement && touched.publicAcknowlegement}
                    errorLabel="This field is required"
                  >
                    <Field
                      component={RadioButton}
                      name="publicAcknowlegement"
                      label="Yes"
                      value="publicAcknowlegement"
                      setOption={() => setFieldValue('publicAcknowlegement', 'yes')}
                      checked={values.publicAcknowlegement === 'yes'}
                      validate={(value: boolean) => !value}
                    />
                    <Field
                      component={RadioButton}
                      name="publicAcknowlegement"
                      label="No"
                      value="publicAcknowlegement"
                      setOption={() => setFieldValue('publicAcknowlegement', 'no')}
                      checked={values.publicAcknowlegement === 'no'}
                      validate={(value: boolean) => !value}
                    />
                  </InputWrapper>

                  <InputWrapper
                    label="Do we have your permission to share your logo on our event website?*"
                    error={
                      errors.permissionToShareCompanyLogo && touched.permissionToShareCompanyLogo
                    }
                    errorLabel="This field is required"
                  >
                    <Field
                      component={RadioButton}
                      name="permissionToShareCompanyLogo"
                      label="Yes"
                      value="permissionToShareCompanyLogo"
                      setOption={() => setFieldValue('permissionToShareCompanyLogo', 'yes')}
                      checked={values.permissionToShareCompanyLogo === 'yes'}
                      validate={(value: boolean) => !value}
                    />
                    <Field
                      component={RadioButton}
                      name="permissionToShareCompanyLogo"
                      label="No"
                      value="permissionToShareCompanyLogo"
                      setOption={() => setFieldValue('permissionToShareCompanyLogo', 'no')}
                      checked={values.permissionToShareCompanyLogo === 'no'}
                      validate={(value: boolean) => !value}
                    />
                  </InputWrapper>
                </FieldsWrapper>

                <PrimaryButton big type="submit" loading={isSubmitting}>
                  {buttonLabel}
                </PrimaryButton>

                {error && (
                  <ErrorLabel error className="fullWidth large">
                    There was an error submitting your registration. Please try again.
                  </ErrorLabel>
                )}
              </Form>
            )}
          </Formik>
        )}
      </FormWrapper>
    </OuterWrapper>
  );
}
