import Image from 'next/image';

import { Title, UpperLabel, Wrapper, Row } from './styles';
import { Container, Section } from '@/components/core';

import { getImgSrcWorkaround } from '@/utils/images';
import { SponsorsType, getSize } from './utils';
import { TagContent } from '@/components/hackathon/TagContent';

const Sponsors = ({ darkTheme, title, list, withTagNames }: SponsorsType) => {
  if (!title || list.length === 0) {
    return null;
  }

  return (
    <Section
      anchorId="sponsors"
      paddingMd={{ top: darkTheme ? '0' : 136, bottom: darkTheme ? undefined : 136 }}
      paddingSm={{ top: darkTheme ? '0' : 104, bottom: darkTheme ? undefined : 104 }}
      paddingXs={{ top: darkTheme ? '0' : 64, bottom: darkTheme ? undefined : 64 }}
    >
      <Container>
        <Title forwardedAs="h3" type="primary">
          {withTagNames ? (
            <TagContent tagName="h3" as="span">
              {title}
            </TagContent>
          ) : (
            title
          )}
        </Title>
        <Wrapper>
          {list.map((tier) => (
            <div key={tier.id}>
              <UpperLabel as={withTagNames ? TagContent : undefined}>{tier.listTitle}</UpperLabel>
              <Row>
                {tier.sponsor.map(({ img, href, id }) => (
                  <a key={id} href={href} target="_blank" rel="noopener noreferrer">
                    <Image
                      src={getImgSrcWorkaround(img?.url)}
                      alt={img?.alternativeText || ''}
                      width={getSize(tier.size)}
                      height={getSize(tier.size) / 2}
                    />
                  </a>
                ))}
              </Row>
            </div>
          ))}
        </Wrapper>
      </Container>
    </Section>
  );
};

export default Sponsors;
