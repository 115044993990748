import { Title } from '@/components/core/Title';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  border-radius: 16px;
  gap: 24px;
  z-index: 1;
  width: 33%;

  @media (max-width: ${(props) => props.theme.breakpoints.max_lg}px) {
    width: 50%;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    width: 100%;
    border-radius: 8px;
    height: min-content;
    padding: 0;
    gap: 16px;
  }

  &.isAiSummit {
    color: var(--title-color);
  }
`;

export const Stats = styled(Title)`
  font-size: 30px;
  line-height: 40px;

  &.isAiSummit {
    padding-bottom: 0;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    font-size: 25px;
    line-height: 32px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    font-size: 20px;
    line-height: 24px;
  }
`;
