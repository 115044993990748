import styled from 'styled-components';
import Image from 'next/image';
import { useContext, useMemo } from 'react';

import NavigationContext from '@/contexts/navigation';
import { getImgData, getImgSrcWorkaround } from '@/utils/images';
import cn from 'classnames';
import EventContext from '@/contexts/event';
import { themeEventName } from '@/utils/themeEventName';

interface ButtonType {
  customLogo?: {};
  onClick: () => void;
}

const LogoWrapper = ({ customLogo, onClick }: ButtonType) => {
  const { scrolled, hover, isOpen } = useContext(NavigationContext);
  const { themeEvent } = useContext(EventContext);
  const customLogoData = useMemo(() => getImgData(customLogo), [customLogo]);
  const isAiSummit = useMemo(() => themeEvent === themeEventName.aiSummit, [themeEvent]);

  return (
    <Logo onClick={onClick} className={cn({ isAiSummit })}>
      {customLogoData ? (
        <Image
          className={cn('custom-logo', { scrolled })}
          src={getImgSrcWorkaround(customLogoData.url)}
          alt={customLogoData.alternativeText || ''}
          width={customLogoData.width}
          height={customLogoData.height}
          priority
        />
      ) : (
        <>
          {scrolled || hover || isOpen ? (
            <img
              data-testid="logo-img"
              className="logo"
              src="/assets/logo/vision_logo.svg"
              alt="Sword Health Digital MSK solutions"
            />
          ) : (
            <img
              data-testid="logo-img"
              className="logo"
              src="/assets/logo/vision_logo__white.svg"
              alt="Sword Health Digital MSK solutions"
            />
          )}
        </>
      )}
    </Logo>
  );
};

export default LogoWrapper;

const Logo = styled.button<ButtonType>`
  border: none;
  box-shadow: none;
  background-color: transparent;
  width: 100%;
  z-index: 1000;
  height: 27.16px;
  cursor: pointer;

  img {
    object-fit: contain;
    object-position: 0 center;
  }

  &.isAiSummit {
    img {
      max-height: 22px;
      &:not(.scrolled) {
        filter: none;
      }
    }
  }
  .logo {
    height: 100%;
    width: 97px;
  }

  .custom-logo {
    max-height: 30px;
    max-width: 100px;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.min_md}px) {
    .logo {
      width: 119px;
    }
  }

  @media (min-width: ${(props) => props.theme.breakpoints.min_lg}px) {
    text-align: left;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;

    .logo {
      height: 28px;
      width: 167px;
    }

    .custom-logo {
      max-height: 40px;
      max-width: 120px;
    }
    &.isAiSummit {
      .custom-logo {
        max-width: 205px;
      }
    }
  }
`;
