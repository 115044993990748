import styled from 'styled-components';
import { Container } from '@/components/core';
import { Content, Subtitle } from '@/components/homepage/Event/Header/styles';
import { CustomTitle as DefaultTitle } from '@/components/shared';

export const Wrapper = styled.div`
  margin-top: 8px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    margin-top: 64px;
  }
`;

export const StyledContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
  column-gap: 80px;
  position: relative;

  ${Content} {
    padding-top: 48px;

    h2 {
      padding-bottom: 32px;

      @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
        padding-bottom: 24px;
      }
    }
  }

  ${Subtitle} {
    color: ${(props) => props.theme.colors.grey.dark};
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    flex-direction: column;

    ${Content} {
      max-width: 640px;
      align-items: flex-start;
      padding-top: 0;
      text-align: left;
      margin-bottom: 40px;
    }
  }
`;

export const InfoContainer = styled.div`
  aspect-ratio: 1/1;
  display: grid;
  margin-top: auto;
  position: relative;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    position: static;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    display: none;
  }
`;

export const InfoAnimation = styled.div`
  height: 130%;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 130%;

  @media (max-width: ${(props) => props.theme.breakpoints.max_lg}px) {
    height: 150%;
    left: 36%;
    width: 180%;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    bottom: -200px;
    height: 110%;
    transform: translateX(-50%);
    left: 50%;
    top: auto;
    width: 150vw;
    z-index: -1;
  }

  img {
    object-fit: contain;

    @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
      object-fit: fill;
    }
  }
`;

export const CustomTitle = styled(DefaultTitle)`
  font-family: ${(props) => props.theme.fonts.title};
`;

export const Info = styled.div`
  align-content: center;
  color: ${(props) => props.theme.colors.white};
  display: grid;
  font-weight: 500;
  font-family: ${({ theme }) => theme.fonts.title};
  font-size: 30px;
  line-height: 40px;
  grid-column: 1;
  grid-row: 1;
  justify-items: center;
  position: relative;
  text-align: center;

  h3 {
    font-size: 76px;
    line-height: 80px;
    padding-bottom: 24px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    display: none;
  }
`;
export const Video = styled.video`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
`;
