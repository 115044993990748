import { MouseEvent, useContext, useMemo } from 'react';
import { useRouter } from 'next/router';
import cn from 'classnames';

import NavigationContext from '@/contexts/navigation';

import NavItem from './NavItem';
import { useUrlAttributes } from '@/utils/useUrlAttributes';
import { themeEventName } from '@/utils/themeEventName';
import EventContext from '@/contexts/event';

type PropsListItem = {
  label?: string;
  url: string;
  extraClass?: any;
  component?: any;
  button?: boolean;
  onClick?: Function;
};

const ListItem = ({ label, url, extraClass, component, button, onClick }: PropsListItem) => {
  const router = useRouter();
  const { setIsOpen, scrolled, hover } = useContext(NavigationContext);
  const { themeEvent } = useContext(EventContext);
  const isAiSummit = useMemo(() => themeEvent === themeEventName.aiSummit, [themeEvent]);
  const urlAttributes = useUrlAttributes(url);

  const Component = component || 'a';

  return (
    <NavItem
      className={cn(extraClass, { isAiSummit })}
      $active={url === router.pathname}
      $white={scrolled || hover}
      $button={button}
    >
      <>
        <Component
          {...urlAttributes}
          href={url}
          onClick={(e: MouseEvent<HTMLAnchorElement>) => {
            if (urlAttributes.onClick) {
              urlAttributes.onClick(e);
            }

            if (onClick) {
              onClick();
            }

            setIsOpen(false);
          }}
        >
          {label}
        </Component>
      </>
    </NavItem>
  );
};

export default ListItem;
