import { useSearchParams } from 'next/navigation';
import { useMemo } from 'react';

export type UtmParamsFiltered = {
  utm_source?: string;
  utm_medium?: string;
  utm_campaign?: string;
  utm_content?: string;
};

export const VALID_UTM_PARAMS = [
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_content',
] as const;

export function geUrlParams(params?: URLSearchParams): UtmParamsFiltered {
  const utm: UtmParamsFiltered = {};

  if (!params) {
    return utm;
  }

  params.forEach((value, key) => {
    const filteredKey = key as keyof UtmParamsFiltered;

    if (filteredKey && VALID_UTM_PARAMS.includes(filteredKey)) {
      utm[filteredKey] = value;
    }
  });

  return utm;
}

export function useCurrentUtm() {
  const searchParams = useSearchParams();

  return useMemo(() => {
    return geUrlParams(searchParams);
  }, [searchParams]);
}
