const fetcher = async <TData extends any>(
  url: string,
  options?: any, // TODO: FIx RequestInit missing type
): Promise<TData> => {
  const response = await fetch(url, options);

  if (response.status < 200 || response.status >= 300) {
    throw response;
  }

  return (await response.json()) as TData;
};

export default fetcher;
