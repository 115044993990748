import React, { memo, useContext } from 'react';
import Image from 'next/image';
import classNames from 'classnames';

import { Container } from '@/components/core';

import Card from './Card';

import { themeEventName } from '@/utils/themeEventName';
import { getImgSrcWorkaround } from '@/utils/images';

import EventContext from '@/contexts/event';
import { SectionHeader } from '@/components/shared';

import { CustomSection, Row, CustomImage, ListWrap } from './styles';

type ListProps = {
  id: string;
  title: string;
  description: string;
  image: {
    url: string;
    alternativeText: string;
  };
};

const Event = ({
  centeredHeader,
  smallImage,
  darkTheme,
  withTagNames,
}: {
  centeredHeader?: boolean;
  smallImage?: boolean;
  darkTheme?: boolean;
  withTagNames?: boolean;
}) => {
  const { event, themeEvent } = useContext(EventContext);
  const isAiSummit = themeEvent === themeEventName.aiSummit;

  if (!event) return null;

  const { list } = event;

  return (
    <CustomSection
      className={classNames({ isAiSummit, darkTheme })}
      paddingXs={{ top: darkTheme ? '0' : '64', bottom: darkTheme ? undefined : '64' }}
      paddingSm={{ top: darkTheme ? '0' : '104', bottom: darkTheme ? undefined : '104' }}
      paddingMd={{ top: undefined, bottom: darkTheme ? undefined : '136' }}
      anchorId="event"
    >
      <Container style={{ position: 'relative', zIndex: 2 }}>
        <SectionHeader sizeLarge="640px" centered={centeredHeader} withTagNames={withTagNames} />

        {list && list.length > 0 && (
          <ListWrap className={classNames({ isAiSummit })}>
            {isAiSummit && (
              <Image
                src="/assets/ai-summit/event-bg.png"
                alt=""
                fill
                sizes="100vw"
                className="bg"
              />
            )}
            {list.map((item: ListProps, index: number) => (
              <Row $positioning={index} key={item.id} className={classNames({ isAiSummit })}>
                <CustomImage
                  width={50}
                  height={50}
                  sizes="100%"
                  src={getImgSrcWorkaround(item.image?.url)}
                  alt={item.image?.alternativeText || ''}
                  $small={smallImage}
                />
                <Card
                  themeEvent={themeEvent}
                  stats={item.title}
                  description={item.description}
                  withTagNames={withTagNames}
                />
              </Row>
            ))}
          </ListWrap>
        )}
      </Container>
    </CustomSection>
  );
};

export default memo(Event);
