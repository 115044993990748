import moment from 'moment';

import RegistrationForm from '@/components/RegistrationForm';
import Section from '@/components/core/Section';
import { Content, Subtitle } from '@/components/homepage/Event/Header/styles';
import useBetterMediaQuery from '@/utils/useBetterMediaQuery';

import theme from '@/utils/styles-variables';
import { CompaniesSlider } from '@/components/shared';
import { StyledContainer, CustomTitle, InfoContainer, InfoAnimation, Info, Video } from './styles';
import { H1 } from '@/components/core/Title';
import { useContext } from 'react';
import EventContext from '@/contexts/event';

export default function RegistrationSection({ registration }: { registration: any }) {
  const { info, status } = useContext(EventContext);
  const isMobile = useBetterMediaQuery(`(max-width: ${theme.breakpoints.max_sm}px)`);

  if (!registration || info?.registrationType === 'external') {
    return null;
  }

  const { title, subTitle, buttonLabel, formTitle, companies } = registration;

  return status === 'before' && info.canRegister ? (
    <Section
      paddingXs={{ top: '0' }}
      paddingSm={{ top: '0', bottom: '104' }}
      paddingMd={{ top: '0' }}
      color={theme.colors.dark_neutral}
      anchorId="register-now"
    >
      <StyledContainer>
        <Content>
          <CustomTitle as="h2">{title}</CustomTitle>
          <Subtitle>{subTitle}</Subtitle>
          <InfoContainer>
            {!isMobile && (
              <InfoAnimation>
                <Video autoPlay playsInline loop muted>
                  <source src="/assets/vision/video-vision-form.mp4" />
                </Video>
              </InfoAnimation>
            )}
            <Info>
              <H1 as="h3">Join us</H1>
              <span>
                {`${moment(info.date).format('MMMM DD')}-${moment(info.endDate).format('DD')}`}
              </span>
              <span>{info.location}</span>
            </Info>
          </InfoContainer>
        </Content>
        <RegistrationForm buttonLabel={buttonLabel} title={formTitle} />
      </StyledContainer>

      <CompaniesSlider content={companies} full />
    </Section>
  ) : null;
}
