import * as Sentry from '@sentry/nextjs';
import marketoClient from '@/lib/marketo-client';
import fetcher from '@/lib/fetcher';
import { NextApiRequest, NextApiResponse } from 'next';

export async function registrationVision(payload: any) {
  return await fetcher('/api/registration-vision', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  });
}

function isValidStatus(status: string) {
  switch (status) {
    case 'created':
      return true;
    case 'updated':
      return true;
    default:
      return false;
  }
}

export async function apiRequestFunc({
  req,
  res,
  formId,
}: {
  req: NextApiRequest;
  res: NextApiResponse;
  formId: number;
}) {
  try {
    const authRes: any = await marketoClient(
      `/identity/oauth/token?grant_type=client_credentials&client_id=${process.env.MARKETO_CLIENT_ID}&client_secret=${process.env.MARKETO_CLIENT_SECRET}`,
    );
    //const ip = req.ip || req.headers['x-forwarded-for'] || req.socket.remoteAddress;

    const visitorData = {
      ...(req.headers['referer'] && {
        pageURL: req.headers['referer'],
      }),
      /*...(ip && {
        leadClientIpAddress: ip,
      }),*/
      ...(req.headers['user-agent'] && {
        userAgentString: req.headers['user-agent'],
      }),
    };

    const leadRes: any = await marketoClient('/rest/v1/leads/submitForm.json', {
      headers: {
        Authorization: `Bearer ${authRes.access_token}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify({
        formId,
        input: [
          {
            leadFormFields: req.body,
            ...(Object.entries(visitorData).length > 0 && {
              visitorData,
            }),
            ...(req?.cookies?._mkto_trk && {
              cookie: req.cookies._mkto_trk,
            }),
          },
        ],
      }),
    });

    const leadStatus = leadRes.result[0].status;

    if (leadRes.success && isValidStatus(leadStatus)) {
      res.status(200).json(JSON.stringify(leadRes));
    } else {
      if (leadRes?.errors?.length > 0) {
        const currentError = leadRes.errors.at(0);
        throw new Error(currentError.message);
      } else {
        throw new Error(`Marketo Request Failed with invalid status ${leadStatus}`);
      }
    }
  } catch (err) {
    Sentry.withScope(function (scope) {
      scope.setTag('form', formId);
      scope.setContext('payload', req.body);

      Sentry.captureException(err);
    });

    res.status(500).json(err);
  }
}
