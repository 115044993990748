import React, { memo } from 'react';
import classNames from 'classnames';

import { Paragraph } from '@/components/core';
import { TagContent } from '@/components/hackathon/TagContent';
import { Wrapper, Stats } from './styles';

import { themeEventName } from '@/utils/themeEventName';

export type CardProps = {
  stats: string;
  description: string;
  withTagNames?: boolean;
  themeEvent?: string;
};

function Card({ stats, description, withTagNames, themeEvent }: CardProps) {
  const isAiSummit = themeEvent === themeEventName.aiSummit;

  return (
    <Wrapper className={classNames({ isAiSummit })}>
      <Stats
        className={classNames({ isAiSummit })}
        type={isAiSummit ? 'secondary' : 'primary'}
        {...(withTagNames ? { tagName: 'h3', forwardedAs: TagContent } : { forwardedAs: 'h3' })}
      >
        {stats}
      </Stats>
      <Paragraph markdownProps={{ elements: ['ul', 'li', 'a', 'p'] }} as="div" richText isMultiLine>
        {description}
      </Paragraph>
    </Wrapper>
  );
}

export default memo(Card);
