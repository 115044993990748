import moment from 'moment';
import React, { useContext, useMemo } from 'react';
import cn from 'classnames';

import DataTimeDisplay from './DataTimeDisplay/DataTimeDisplay';
import ScrollIndicator from './ScrollIndicator';
import { getImgSrcWorkaround } from '@/utils/images';

import {
  Wrapper,
  ContainerElement,
  Header,
  HeaderWrapper,
  HeaderTitle,
  HeaderEventDate,
  DisplayWrapper,
  ButtonWrapper,
  Video,
  VideoOverlay,
  Logo,
} from './styles';

import EventContext from '@/contexts/event';
import { WhiteButton } from '@/components/core/Button';
import { TertiaryButton } from '@/components/vision/Button';
import { Section } from '@/components/core';
import { MarkdownRichText } from '@/components/shared';
import { themeEventName } from '@/utils/themeEventName';
import Image from 'next/image';

export default function Countdown({ hasVideo = true, hasScrollIndicator = true }) {
  const { info, header, status, themeEvent, logo } = useContext(EventContext);
  const { date, location, endDate, canRegister, registrationType, registrationUrl } = info;
  const isExternalRegistration = registrationType === 'external';
  const { title, video, showLogo } = header;
  const isAiSummit = useMemo(() => themeEvent === themeEventName.aiSummit, [themeEvent]);
  const Button = useMemo(
    () => (themeEvent === themeEventName.aiSummit ? TertiaryButton : WhiteButton),
    [themeEvent],
  );
  return (
    <Wrapper>
      {hasVideo && video && (
        <>
          {!isAiSummit && <VideoOverlay />}
          <Video autoPlay playsInline loop muted>
            <source src={getImgSrcWorkaround(video.url)} />
          </Video>
        </>
      )}
      <Section className={cn({ isAiSummit })}>
        <ContainerElement>
          <Header className={cn({ isAiSummit })}>
            {logo && showLogo && (
              <Logo className={cn({ space: !title })}>
                <Image
                  src={getImgSrcWorkaround(logo.url)}
                  alt={logo.alternativeText || ''}
                  fill
                  sizes="(max-width: 991px) 100vw, 886px"
                />
              </Logo>
            )}
            <HeaderWrapper>
              {title && (
                <HeaderTitle className={cn({ isAiSummit })}>
                  <MarkdownRichText elements={['br']}>{title}</MarkdownRichText>
                </HeaderTitle>
              )}
              <HeaderEventDate className={cn({ isAiSummit })}>
                {isAiSummit ? (
                  <>
                    {`${moment(date).format('dddd')}, ${moment(date).format('MMMM DD')} > ${moment(date).format('YYYY')}`}
                    <br />
                    <MarkdownRichText elements={['em']}>{location}</MarkdownRichText>
                  </>
                ) : (
                  `${moment(date).format('MMMM DD')}-${moment(endDate).format('DD')} in ${location}`
                )}
              </HeaderEventDate>
            </HeaderWrapper>
          </Header>
          <DisplayWrapper>
            <DataTimeDisplay />
          </DisplayWrapper>
          {canRegister && status == 'before' && (
            <ButtonWrapper className={cn({ isAiSummit })}>
              <Button big href={isExternalRegistration ? registrationUrl : '#register-now'}>
                Register now
              </Button>
            </ButtonWrapper>
          )}
        </ContainerElement>
      </Section>
      {hasScrollIndicator && <ScrollIndicator />}
    </Wrapper>
  );
}
