import styled from 'styled-components';
import { UpperText } from '@/components/core/Title';
import { CustomTitle } from '@/components/shared';

export const Title = styled(CustomTitle)`
  text-align: center;
  max-width: unset;
  padding-bottom: 80px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    padding-bottom: 64px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    padding-bottom: 40px;
  }
`;

export const UpperLabel = styled(UpperText)`
  opacity: 1;
  font-weight: 700;
  letter-spacing: 2px;
  color: var(--label-color, ${({ theme }) => theme.colors.grey.default});
  padding-bottom: 24px;
  text-align: center;
`;

export const Wrapper = styled.div`
  display: grid;
  gap: 80px;
`;

export const Row = styled.div`
  display: flex;
  row-gap: 0;
  column-gap: 64px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  img {
    object-fit: contain;
  }
`;
