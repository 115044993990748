import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
`;

export const Header = styled.header`
  color: ${({ theme }) => theme.colors.grey[800]};
  text-align: center;
`;
