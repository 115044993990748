const inputs = (props) => `
	width: 100%;
	height: 48px;
	background-color: ${props.theme.colors.white};
	color: ${props.theme.colors.grey.dark};
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
	padding: 0 20px;
	border: 1px solid #D8DBE1;
	border-radius: 4px;
	transition: all 0.3s ease;

	&.textarea {
		height: 100px;
		resize: none;
		padding: 10px 20px;
	}

	&::placeholder {
		color: rgba(48, 38, 29, 0.5);
	}
	
	&:hover{
	    border-color: #A4AAB6;
	}

	&:focus {
		outline: none;
		border-color: #A4AAB6;
	}

	&.error {
		color: ${(props) => props.theme.colors.hard_red};
		border-color: var(--error-color, ${(props) => props.theme.colors.hard_red});
	}
`;

export { inputs };
