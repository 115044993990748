import styled, { css } from 'styled-components';
import ScrollSvg from '@/public/assets/vision/scroll-indicator.svg';
import ScrollSvgTablet from '@/public/assets/vision/scroll-indicator-tablet.svg';

type ScrollType = {
  $scrolled: boolean;
};

const StyleBase = css`
  position: absolute;
  top: calc(100vh - 106px);
  left: 50%;
  transform: translateX(-50%);
  transition: opacity 300ms ease-in;
  z-index: 2;

  @media (max-height: 800px) and (orientation: landscape) {
    display: none;
  }
`;

export const StyledScrollDesktop = styled(ScrollSvg)<ScrollType>`
  ${StyleBase}

  ${(props) =>
    props.$scrolled &&
    css`
      opacity: 0;
    `};
`;

export const StyledScrollTablet = styled(ScrollSvgTablet)<ScrollType>`
  ${StyleBase}

  ${(props) =>
    props.$scrolled &&
    css`
      opacity: 0;
    `};
`;
