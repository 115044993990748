import styled from 'styled-components';
import cn from 'classnames';

import { inputs } from '@/utils/mixins';

import InputLabel from './InputLabel';
import ErrorLabel from './ErrorLabel';

const Select = ({
  className,
  label,
  placeholder,
  value,
  name,
  ariaLabel,
  onChange,
  disabled,
  error,
  errorLabel,
  field,
  required,
  options = [],
}) => {
  return (
    <div className={className}>
      {label && <InputLabel>{label}</InputLabel>}

      <select
        className={cn('textbox', { error })}
        value={value}
        name={name}
        aria-label={ariaLabel}
        onChange={onChange}
        disabled={disabled}
        required={required}
        {...field}
      >
        <option value="" disabled hidden>
          {placeholder}
        </option>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>

      {error && errorLabel && <ErrorLabel error={error}>{errorLabel}</ErrorLabel>}
    </div>
  );
};

const SelectStyled = styled(Select)`
  margin-bottom: 20px;

  .textbox {
    ${inputs};

    appearance: none;
    background-image: url(/assets/caret.svg);
    background-repeat: no-repeat;
    background-position: right 20px center;
    background-size: 10px;
    font-family: inherit;
    padding-right: 40px;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export default SelectStyled;
