import { useContext } from 'react';
import Image from 'next/image';
import classNames from 'classnames';
import navigateToId from '@/utils/navigation';
import EventContext from '@/contexts/event';
import { getImgSrcWorkaround } from '@/utils/images';
import { Container, Paragraph } from '@/components/core';
import { themeEventName } from '@/utils/themeEventName';

import {
  CustomSection,
  Content,
  Button,
  CustomTitle,
  CustomUpperText,
  ImageElement,
  Wrapper,
  Arrow,
} from './styles';
import { TagContent } from '@/components/hackathon/TagContent';

const KeynoteSpeaker = ({ withTagNames }: { withTagNames?: boolean }) => {
  const { keynoteSpeaker, themeEvent } = useContext(EventContext);

  if (!keynoteSpeaker) return null;

  const { title, name, description, textLink, image } = keynoteSpeaker;

  return (
    <CustomSection
      className={classNames({ aiSummit: themeEvent === themeEventName.aiSummit })}
      paddingXs={{ top: 64, bottom: 64 }}
      paddingSm={{ top: 104, bottom: 104 }}
      paddingMd={{ top: 136, bottom: 136 }}
    >
      <Container>
        <Wrapper>
          <Content>
            <CustomUpperText $type="primary" as="h2">
              {title}
            </CustomUpperText>
            <CustomTitle
              type="primary"
              as={withTagNames ? TagContent : undefined}
              tagName="h3"
              forwardedAs="h3"
            >
              {name}
            </CustomTitle>
            <Paragraph
              as={withTagNames ? TagContent : 'div'}
              markdownProps={{ elements: ['a', 'p'] }}
              richText
              isMultiLine
            >
              {description}
            </Paragraph>
            <Button onClick={() => navigateToId('agenda')}>
              {textLink}
              <Arrow aria-hidden="true" />
            </Button>
          </Content>
          <ImageElement>
            <Image
              src={getImgSrcWorkaround(image.url)}
              alt={image.alternativeText || ''}
              fill
              sizes="100vw"
              priority
            />
          </ImageElement>
        </Wrapper>
      </Container>
    </CustomSection>
  );
};

export default KeynoteSpeaker;
