import moment from 'moment';

import {
  InnerWrapper,
  Item,
  OuterWrapper,
  Wrapper,
  DesktopDate,
  MobileDate,
  Content,
} from './styles';

type Date = {
  date: string;
};

const Nav = ({
  darkTheme,
  dates,
  active,
  handleClick,
  fixed,
}: {
  darkTheme?: boolean;
  dates: Date[];
  active: number;
  handleClick: Function;
  fixed: boolean;
}) => {
  if (!dates.length || dates.length < 2) {
    return null;
  }

  return (
    <Content>
      <Wrapper $darkTheme={darkTheme} $fixed={fixed}>
        <OuterWrapper>
          {dates.map((item, index) => (
            // @ts-ignore
            <InnerWrapper
              key={index}
              $active={active === index}
              $darkTheme={darkTheme}
              onClick={() => handleClick(index)}
              type="button"
            >
              <Item>
                <DesktopDate>{moment(item.date).format('dddd, D')}</DesktopDate>
                <MobileDate>{moment(item.date).format('ddd, D')}</MobileDate>
              </Item>
            </InnerWrapper>
          ))}
        </OuterWrapper>
      </Wrapper>
    </Content>
  );
};

export default Nav;
