import { memo, useContext } from 'react';

import { Container, Paragraph, Section } from '@/components/core';
import { TertiaryButton } from '@/components/vision/Button';
import { UpperText } from '@/components/core/Title';

import { Header, Title, VenueActions, DirectionButton, SliderWrapper } from './styles';

import VenueSlider from '@/components/shared/VenueSlider';

import EventContext from '@/contexts/event';
import { TagContent } from '@/components/hackathon/TagContent';
import theme from '@/utils/styles-variables';

const Venue = ({ darkTheme, withTagNames }: { darkTheme?: boolean; withTagNames?: boolean }) => {
  const { venue } = useContext(EventContext);

  if (!venue) return null;

  const {
    address,
    address2,
    redButtonLabel,
    redButtonLink,
    secondaryButtonLabel,
    secondaryButtonLink,
    title,
    upperLabel,
  } = venue;

  const hasRedButton = redButtonLabel && redButtonLink;
  const hasSecondaryButton = secondaryButtonLabel && secondaryButtonLink;

  return (
    <Section
      anchorId="venue"
      paddingMd={{ top: darkTheme ? '0' : 136, bottom: darkTheme ? undefined : 136 }}
      paddingSm={{ top: darkTheme ? '0' : 104, bottom: darkTheme ? undefined : 104 }}
      paddingXs={{ top: darkTheme ? '0' : 64, bottom: darkTheme ? undefined : 64 }}
      color={darkTheme ? 'transparent' : theme.colors.white}
    >
      <Container>
        <Header>
          <UpperText $type="primary" as="p">
            {upperLabel}
          </UpperText>
          <Title
            dangerouslySetInnerHTML={{ __html: `${title}` }}
            $type="primary"
            as={withTagNames ? TagContent : undefined}
            tagName="h3"
            forwardedAs="h3"
          />
          <Paragraph isMarkdown={false}>
            {address}
            {address2 && (
              <>
                <br />
                {address2}
              </>
            )}
          </Paragraph>
          {(hasRedButton || hasSecondaryButton) && (
            <VenueActions>
              {hasRedButton && (
                <DirectionButton big href={redButtonLink}>
                  {redButtonLabel}
                </DirectionButton>
              )}
              {hasSecondaryButton && (
                <TertiaryButton big href={secondaryButtonLink}>
                  {secondaryButtonLabel}
                </TertiaryButton>
              )}
            </VenueActions>
          )}
        </Header>
      </Container>
      <SliderWrapper>
        <VenueSlider
          content={{ data: venue.images }}
          smallButton
          largeButton={false}
          showShapes={false}
          darkTheme={darkTheme}
        />
      </SliderWrapper>
    </Section>
  );
};

export default memo(Venue);
