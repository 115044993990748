import styled from 'styled-components';
import { Title, UpperText } from '@/components/core/Title';
import ArrowButton from '@/public/assets/vision/arrow-keynote-speaker.svg';
import { Section } from '@/components/core';

export const CustomSection = styled(Section)`
  &.aiSummit {
    background-color: ${({ theme }) => theme.colors.white};
  }
`;

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(328px, 1fr));
  column-gap: 80px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    column-gap: 40px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    row-gap: 40px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_xs}px) {
    grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
  }
`;
export const Content = styled.div`
  display: grid;
  row-gap: 32px;
  place-content: center;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    row-gap: 24px;
  }
`;
export const CustomUpperText = styled(UpperText)`
  font-weight: 700;
  font-family: ${(props) => props.theme.fonts.title};
  padding: 0;
  letter-spacing: 2px;
`;
export const CustomTitle = styled(Title)`
  font-family: ${(props) => props.theme.fonts.title};
  font-style: normal;
  font-size: 45px;
  line-height: 56px;
  padding-bottom: 0;

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    font-size: 30px;
    line-height: 40px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_xs}px) {
    font-size: 25px;
    line-height: 32px;
  }
`;

export const Arrow = styled(ArrowButton)`
  margin-left: 4px;
  transition: all 0.3s ease;
`;
export const Button = styled.button`
  font-family: ${(props) => props.theme.fonts.title};
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  color: var(--link-color, ${(props) => props.theme.colors.red});
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease;
  text-transform: var(--button-text-transform, none);

  &:hover {
    color: var(--link-hover-color, ${(props) => props.theme.colors.grey.dark});
    ${Arrow} {
      transform: translateX(3px);
    }
  }
`;
export const ImageElement = styled.figure`
  position: relative;
  aspect-ratio: 1/1;

  img {
    object-fit: contain;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    max-height: 432px;
    width: 100%;
  }
`;
