import styled, { css } from 'styled-components';

import { H2 } from '@/components/core/Title';
import { PrimaryButton } from '@/components/core/Button';

export const Title = styled(H2)`
  line-height: 56px;
  font-size: 45px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    font-size: 30px;
    line-height: 40px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    font-size: 25px;
    line-height: 32px;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

export const SliderWrapper = styled.div`
  ${({ theme }) => css`
    height: 240px;
    margin-top: 40px;

    @media (min-width: ${theme.breakpoints.min_md}px) {
      height: 320px;
      margin: 64px auto 0;
    }
    @media (min-width: ${theme.breakpoints.min_lg}px) {
      height: 500px;
    }
  `}
`;

export const VenueActions = styled.div`
  ${({ theme }) => css`
    display: flex;
    margin-top: 24px;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: column;
    gap: 16px;
    a,
    button {
      width: 100%;
      text-align: center;
    }

    @media (min-width: 435px) {
      flex-direction: row;
      justify-content: center;
      width: 100%;
      max-width: 397px;
      a,
      button {
        width: initial;
      }
    }

    @media (min-width: ${theme.breakpoints.min_md}px) {
      margin-top: 32px;
      flex-direction: row;
      align-items: center;
    }
  `}
`;

export const DirectionButton = styled(PrimaryButton)`
  margin-bottom: 8px;

  @media (min-width: 435px) {
    margin-bottom: 0;
  }
`;
