import styled from 'styled-components';

const InputLabel = styled.label`
  display: block;
  color: ${(props) => props.theme.colors.gray};
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 8px;
`;

export default InputLabel;
