import React, { useContext, useMemo } from 'react';
import { RemoveScroll } from 'react-remove-scroll';
import styled, { css } from 'styled-components';
import cn from 'classnames';

import NavigationContext from '@/contexts/navigation';
import EventContext from '@/contexts/event';
import { Container } from '@/lib/flexbox';

import Hamburguer from './Burguer';
import LogoButton from './Logo';
import { MenuList } from './List';
import navigateToId from '@/utils/navigation';
import { PrimaryButton, WhiteButton } from '@/components/core/Button';
import { useReducedMotion } from '@/utils/useReducedMotion';
import { StrapiImage } from '@/types/strapi';
import useBetterMediaQuery from '@/utils/useBetterMediaQuery';
import theme from '@/utils/styles-variables';
import { themeEventName } from '@/utils/themeEventName';

type NavigationType = {
  customLogo?: StrapiImage;
  darkTheme?: boolean;
  fixed?: boolean;
  showAgenda?: boolean;
};

const Navigation = ({ customLogo, darkTheme, fixed, showAgenda }: NavigationType) => {
  const prefersReducedMotion = useReducedMotion();
  const { scrolled, isOpen, hover } = useContext(NavigationContext);
  const { info, status, themeEvent } = useContext(EventContext);
  const { canRegister, registrationType, registrationUrl } = info;
  const isExternalRegistration = registrationType === 'external';
  const isDesktop = useBetterMediaQuery(`(min-width: ${theme.breakpoints.min_lg}px)`);
  const isAiSummit = useMemo(() => themeEvent === themeEventName.aiSummit, [themeEvent]);
  const Button = useMemo(
    () => (!darkTheme && (scrolled || hover || isAiSummit) ? PrimaryButton : WhiteButton),
    [darkTheme, scrolled, hover, isAiSummit],
  );
  return (
    <RemoveScroll enabled={!isDesktop && isOpen}>
      <MenuWrapper
        $white={scrolled || hover || isAiSummit}
        $isOpen={isOpen}
        $fixed={fixed}
        className={cn({ isAiSummit })}
      >
        <Container>
          <Menu>
            <MenuItem className={cn({ isAiSummit })}>
              <LogoButton
                onClick={() => navigateToId('__next', prefersReducedMotion, false)}
                customLogo={customLogo}
              />
            </MenuItem>
            <MenuItem>
              <MenuList
                canRegister={canRegister}
                isExternalRegistration={isExternalRegistration}
                showAgenda={showAgenda}
                info={info}
                white={scrolled || hover || isAiSummit}
                prefersReducedMotion={prefersReducedMotion}
                darkTheme={darkTheme}
              />
            </MenuItem>
            <MenuItem>
              <Hamburguer />
            </MenuItem>

            {canRegister && status == 'before' && (
              <JoinButton open={isOpen}>
                <Button href={isExternalRegistration ? registrationUrl : '#register-now'}>
                  Register now
                </Button>
              </JoinButton>
            )}
          </Menu>
        </Container>
      </MenuWrapper>
    </RemoveScroll>
  );
};

export default Navigation;

type MenuWrapperType = {
  $white?: boolean;
  $fixed?: boolean;
  $isOpen?: boolean;
};

const MenuWrapper = styled.div<MenuWrapperType>`
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  z-index: 1000;
  background-color: transparent;
  transition: all 0.3s ease;

  ${(props) =>
    props.$white &&
    css`
      background-color: var(--nav-bg, ${(props) => props.theme.colors.white});
      box-shadow: var(--nav-bg, rgba(233, 220, 211, 0.4)) 0 2px 10px 0;
    `};

  &.isAiSummit {
    box-shadow: none;
  }
  ${(props) =>
    props.$fixed &&
    css`
      position: fixed;
      top: 0;
      @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
        min-height: 60px;
      }
    `};

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    ${(props) =>
      props.$isOpen &&
      css`
        border-bottom: 1px solid var(--nav-bg, rgba(31, 34, 44, 0.1));
      `};
  }
`;

const Menu = styled.div`
  display: flex;
  flex-direction: row;
  font-family: ${(props) => props.theme.fonts.title};
  justify-content: space-between;
`;

const MenuItem = styled.div`
  &:first-child {
    order: 1;
    width: 15%;
    padding: 19px 0;
  }

  &:nth-child(2) {
    order: 2;
  }

  &:nth-child(3) {
    order: 3;
  }

  &.isAiSummit {
    &:first-child {
      padding: 20px 0;
    }
  }
  @media (min-width: ${(props) => props.theme.breakpoints.min_lg}px) {
    line-height: 40px;
    &:nth-child(3) {
      display: none;
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    padding: 16px 0;
    z-index: 10000;
    height: 60px;
    &:first-child {
      width: 50%;
      z-index: 10000000;
    }

    &:nth-child(2) {
      width: 50%;
    }
  }
`;

type JoinButtonType = {
  open?: boolean;
};

const JoinButton = styled.div<JoinButtonType>`
  display: none;

  @media (min-width: ${(props) => props.theme.breakpoints.min_md}px) and (max-width: ${(props) =>
      props.theme.breakpoints.max_md}px) {
    display: unset;
    position: absolute;
    content: '';
    top: 10px;
    right: 84px;
    z-index: 10000000;
    opacity: 1;
    transition: opacity 0.3s ease;

    ${(props) =>
      props.open &&
      css`
        opacity: 0;
        pointer-events: none;
      `};
  }
`;
