import { useContext, useState } from 'react';
import { useInView } from 'react-intersection-observer';

import { Container, Paragraph, Section } from '@/components/core';
import { Title, UpperText } from '@/components/core/Title';

import Event from '@/components/homepage/Agenda/Event';
import Nav from './Nav';

import { Wrapper, Header } from './styles';

import EventContext from '@/contexts/event';
import navigateToId from '@/utils/navigation';
import { TagContent } from '@/components/hackathon/TagContent';
import { MarkdownRichText } from '@/components/shared';

const Agenda = ({
  darkTheme,
  id = 'agenda',
  showIntro = true,
  showSchedule = true,
  spacerTop = true,
  withTagNames,
}: {
  darkTheme?: boolean;
  id?: string;
  showIntro?: boolean;
  showSchedule?: boolean;
  spacerTop?: boolean;
  withTagNames?: boolean;
}) => {
  const { agenda, agendaEntries } = useContext(EventContext);
  const [active, setActive] = useState(0);
  const [ref, inView] = useInView({
    threshold: 0,
    initialInView: false,
    rootMargin: '-80px 0% -80%',
  });

  if (!agenda || !agendaEntries) return null;

  const { upperLabel, title, description, hideAgenda } = agenda;

  if (hideAgenda) return null;

  function handleClick(index: number) {
    if (active !== index) {
      setActive(index);
      navigateToId('agenda');
    }
  }

  return (
    <Section
      anchorId={id}
      paddingMd={{ top: !spacerTop || darkTheme ? '0' : undefined }}
      paddingXs={{ top: !spacerTop || darkTheme ? '0' : undefined }}
      paddingSm={{ top: !spacerTop || darkTheme ? '0' : undefined }}
    >
      <Wrapper>
        <Container>
          {showIntro && (
            <Header>
              {upperLabel && (
                <UpperText $type="primary" as="p">
                  {upperLabel}
                </UpperText>
              )}
              {title && (
                <Title as="h3" type="primary">
                  {withTagNames ? (
                    <TagContent tagName="h3" as="span">
                      {title}
                    </TagContent>
                  ) : (
                    <MarkdownRichText>{title}</MarkdownRichText>
                  )}
                </Title>
              )}
              {description && <Paragraph>{description}</Paragraph>}
            </Header>
          )}
          <Nav
            active={active}
            handleClick={handleClick}
            dates={agendaEntries}
            fixed={inView}
            darkTheme={darkTheme}
          />
          <div ref={ref}>
            <Event
              event={agendaEntries[active]}
              showSpeakers={showIntro}
              showSchedule={showSchedule}
            />
          </div>
        </Container>
      </Wrapper>
    </Section>
  );
};

export default Agenda;
