import React from 'react';
import cn from 'classnames';
import styled, { css } from 'styled-components';

const RadioButton = ({ className, clearStyle, value, checked, setOption, label, field, name }) => {
  return (
    <RadioWrapper
      className={cn({ 'clear-style': clearStyle }, className)}
      checked={checked}
      onClick={setOption}
    >
      <label className="radio-label-wrapper">
        <CustomInput type="radio" value={value} name={name} {...field} />
        {label && <span className="option-label">{label}</span>}
        <span className="radio-icon" />
      </label>
    </RadioWrapper>
  );
};

const RadioWrapper = styled.div`
  display: flex;
  margin-bottom: 10px;
  padding: 14px 0;
  width: 100%;
  border-radius: 4px;

  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    background-color: var(--button-hover-bg, #e7eaf0);
    cursor: pointer;
    transition: background-color 400ms ease-in-out;

    .radio-icon {
      border: 1px solid ${(props) => props.theme.colors.blue};
      transition: border-color 400ms ease-in-out;
    }
  }

  ${(props) =>
    props.checked &&
    css`
      .radio-label-wrapper {
        .radio-icon {
          border: 1px solid ${(props) => props.theme.colors.blue};

          &:before {
            content: '';
            position: absolute;
            top: 5px;
            left: 5px;
            width: 12px;
            height: 12px;
            background-color: ${(props) => props.theme.colors.blue};
            border-radius: 100%;
            display: block;
          }
        }
      }
    `}

  .option-label {
    color: ${(props) => props.theme.colors.gray};
    font-size: 16px;
    font-weight: 500;
    line-height: 28px;
  }

  .radio-label-wrapper {
    cursor: pointer;
    position: relative;
    width: 100%;
    display: flex;
    gap: 8px;
    justify-content: space-between;
    padding: 0 16px;
  }

  .radio-icon {
    cursor: pointer;
    display: block;
    width: 24px;
    height: 24px;
    background-color: ${(props) => props.theme.colors.white};
    border: 1px solid #c5c9d2;
    border-radius: 100%;
    position: relative;
    flex-shrink: 0;
  }

  &.clear-style {
    padding: 0;
    border: 0;

    @media (min-width: ${(props) => props.theme.breakpoints.max_md}px) {
      &:hover {
        background: transparent;
      }
    }

    ${(props) =>
      props.checked &&
      css`
        background: transparent;
      `}
  }
`;

const CustomInput = styled.input`
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
`;

export default RadioButton;
