import styled, { css } from 'styled-components';

type WrapperType = {
  $darkTheme?: boolean;
  $fixed?: boolean;
};

const wrapperModifiers = {
  default: () => css`
    background-color: var(--agenda-nav-bg, ${(props) => props.theme.colors.darker_neutral});
    mix-blend-mode: normal;
  `,
  fixed: () => css`
    background-color: var(--agenda-nav-bg, ${(props) => props.theme.colors.darker_neutral});
    mix-blend-mode: normal;
    position: fixed;
    top: 92px;
    left: 50%;
    transform: translateX(-50%);
    box-shadow: 0 4px 12px rgba(121, 100, 90, 0.3);

    @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
      top: 44px;
    }

    @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
      top: 60px;
    }
  `,
};

export const Content = styled.div`
  min-height: 56px;
  margin: 32px auto 0;
`;

export const Wrapper = styled.div<WrapperType>`
  ${({ $fixed }) => css`
    color: ${(props) => `var(--text-color, ${props.theme.colors.grey.dark})`};
    border-radius: 300px;
    max-width: fit-content;
    width: 100%;
    margin: 0 auto;
    z-index: 6;
    ${!$fixed && wrapperModifiers.default()};
    ${$fixed && wrapperModifiers.fixed()};

    @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
      overflow: auto;
      white-space: nowrap;
      scrollbar-width: none; // hide scrollbar in Firefox
      margin-top: 24px;
      ::-webkit-scrollbar {
        display: none;
      }

      @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
        margin-top: 8px;
      }
    }
  `}

  ${(props) =>
    props.$darkTheme &&
    css`
      border: 1px solid currentColor;
    `}
`;

export const OuterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
`;

export const Item = styled.span`
  display: block;
  font-family: ${(props) => props.theme.fonts.title};
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
`;

export const DesktopDate = styled.span`
  display: none;
  @media (min-width: ${(props) => props.theme.breakpoints.min_md}px) {
    display: block;
  }
`;

export const MobileDate = styled.span`
  display: none;
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    display: block;
  }
`;

export const InnerWrapper = styled.button<{ $active?: boolean; $darkTheme?: boolean }>`
  background-color: var(--agenda-nav-bg, ${(props) => props.theme.colors.darker_neutral});
  border-radius: 300px;
  padding: 8px 16px;

  &:hover {
    cursor: pointer;
    color: var(--agenda-nav-hover-color, ${(props) => props.theme.colors.red});
    transition: 0.2s;
    transition-timing-function: ease-in-out;
  }

  ${(props) =>
    props.$active &&
    css`
      background-color: ${(props) => props.theme.colors.white};
      transition: background-color 0.3s ease-in-out;
      color: var(--agenda-nav-active-color, ${(props) => props.theme.colors.red});
      transition: 0.3s;
      transition-timing-function: ease-in-out;
      pointer-events: none;
    `}
`;
