import styled from 'styled-components';
import cn from 'classnames';

import ErrorIcon from '../icons/error.svg';

const ErrorLabel = ({ error, children, className }) => (
  <div className={cn(className, { error })}>
    <div className="error-icon">
      <ErrorIcon aria-hidden="true" />
    </div>
    <div className="error-label">{children}</div>
  </div>
);

const ErrorLabelStyled = styled(ErrorLabel)`
  visibility: hidden;
  height: 0;
  opacity: 0;
  transition: all 0.3s ease;
  display: inline-flex;
  align-items: center;
  margin-top: 4px;
  color: var(--error-color, ${(props) => props.theme.colors.negative.default});

  &.error {
    visibility: visible;
    height: 20px;
    opacity: 1;
  }

  &.large {
    height: auto;
  }

  .error-icon {
    margin-right: 6px;
  }

  .error-label {
    color: var(--error-color, ${(props) => props.theme.colors.hard_red});
    font-size: 12px;
    line-height: 20px;
  }
`;

export default ErrorLabelStyled;
