import InputLabel from '@/components/core/forms/input/InputLabel';
import ErrorLabel from '@/components/core/forms/input/ErrorLabel';

const InputWrapper = ({ label, className = '', children, error, errorLabel }) => (
  <div className={className}>
    <InputLabel>{label}</InputLabel>
    {children}
    {error && errorLabel && <ErrorLabel error={error}>{errorLabel}</ErrorLabel>}
  </div>
);

export default InputWrapper;
