import styled from 'styled-components';
import cn from 'classnames';

import { inputs } from '@/utils/mixins';

import InputLabel from './InputLabel';
import ErrorLabel from './ErrorLabel';

const Textbox = ({
  className,
  label,
  type,
  placeholder,
  value,
  name,
  ariaLabel,
  onChange,
  disabled,
  error,
  errorLabel,
  field,
  required,
}) => {
  const TagName = type === 'textarea' ? 'textarea' : 'input';

  return (
    <div className={className}>
      {label && <InputLabel>{label}</InputLabel>}

      <TagName
        className={cn('textbox', type, { error })}
        type={type}
        placeholder={placeholder}
        value={value}
        name={name}
        aria-label={ariaLabel}
        onChange={onChange}
        disabled={disabled}
        required={required}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...field}
      />

      {error && errorLabel && <ErrorLabel error={error}>{errorLabel}</ErrorLabel>}
    </div>
  );
};

const TextboxStyled = styled(Textbox)`
  margin-bottom: 20px;

  .textbox {
    ${inputs};
  }
`;

export default TextboxStyled;
