import styled from 'styled-components';
import { Title } from '../core/Title';

export const OuterWrapper = styled.div`
  position: relative;
`;

export const FormWrapper = styled.div`
  position: relative;
  z-index: 100;
  background-color: #fff;
  box-shadow: 0 20px 50px rgba(35, 35, 35, 0.1);
  border-radius: 16px;
  max-width: 640px;
  padding: 64px 54px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    max-width: 100%;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    width: 100vw;
    max-width: 100vw;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 0;
    box-shadow: none;

    [type='submit'] {
      width: 100%;
    }
  }
`;

export const Image = styled.div`
  position: absolute;
  top: -80px;
  left: 322px;
  right: 0;
  bottom: -80px;
  max-width: 942px;
  background: url('/assets/vision/vision-form.jpg') no-repeat center center;
  background-size: cover;
  border-radius: 16px;
  overflow: hidden;
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    display: none;
  }
`;

export const BigShape = styled.div`
  position: absolute;
  bottom: -43px;
  left: -54px;
  height: 143px;
  width: 143px;
  background-color: #f8d9c9;
  border-radius: 50%;
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    display: none;
  }
`;

export const RoundedShape = styled.div`
  position: absolute;
  bottom: -66px;
  left: 76px;
  height: 32px;
  width: 32px;
  background-color: #fff;
  border: 4px solid #f46262;
  border-radius: 50%;
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    display: none;
  }
`;

export const BigBall = styled.div`
  position: absolute;
  bottom: 12px;
  right: -268px;
  width: 720px;
  height: 720px;
  background: linear-gradient(225deg, #f7f4f2 14.64%, rgba(247, 244, 242, 0) 85.36%);
  border-radius: 50%;
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    display: none;
  }
`;

export const FieldsWrapper = styled.div`
  margin: 48px 0;

  font-family: ${(props) => props.theme.fonts.body};
`;

export const SuccessWrapper = styled.div`
  height: 100%;
  width: 100%;
  background-color: white;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  gap: 20px;

  min-height: 600px;
`;
export const FormTitle = styled(Title)`
  font-weight: 700;
  font-family: ${(props) => props.theme.fonts.title};
`;
export const SuccessTitle = styled.h4`
  color: ${(props) => props.theme.colors.grey.dark};
  font-family: ${(props) => props.theme.fonts.title};
  font-weight: 700;
  font-size: 25px;
  line-height: 32px;
  text-align: center;
`;

export const SuccessDescription = styled.p`
  color: ${(props) => props.theme.colors.grey[800]};
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  text-align: center;
  max-width: 480px;
`;
